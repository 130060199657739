.elements-tree
  margin-bottom 0px

.tree-element
  width 100%
  height 35px
  font-weight 500
  line-height 32px
  cursor: pointer
  
  &:hover
    background-color second-color-bright
    color #FFF
  p
    position absolute
    left 30px
    display inline-block
    
  &.main-city 
    font-size 20px
    p
      left 10px
  
  .glyphicon.glyphicon-plus
  .glyphicon.glyphicon-minus
    padding-left 10px
  
  .change-area
    position absolute
    display inline-block
    width 50px
    height 35px
    right 0px
    border initial
    border-radius 0
    background transparent
    &:hover
      background-color third-color
      color #FFF
    