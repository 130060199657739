@media screen and (min-width: screen-mobile-breakpoint)
  .app-view-mobile
    display none

page-layout-margins = 0 50px 0 50px
.moveLeft
  transition:0.3s linear all;
  transform: translateX(130px);

.moveOutLeft
  transition:0.3s linear all;
  transform: translateX(0px);

.charts-view
.tables-view
.page-title
  // max-height: 50%
  display inline-block
  animation-fill-mode forwards

.charts-view
  width 90%
  left 30px
  height 400px
  &.moveLeft
    transform: translateX(150px);

.tables-view
  left 55px
  position relative
  width 90%
  &.moveLeft
    transform: translateX(170px);
    width 80%

.charts-view
.page-title
  margin page-layout-margins

.page-title
  margin-top 75px
  margin-bottom 25px
  margin-left 65px
  width 100%
  &.moveLeft
    transform: translateX(160px);

.menu-btn
  height 100%
  display inline-block
  z-index: 1000
  font-size 20px
  border-radius 0
  border none
  background main-color
  color #FFF
  position relative
  &.active
  &:hover
  &:focus  
    background second-color
    color #FFF
  &.open-menu
    width 50px
    line-height 35px
    padding-left 8px
  &.nav-btn
    width 150px
    text-align left
    padding-left 15px
    &.chart-btn
      width 125px
    &.stats-btn
      width 140px
    .glyphicon
      position absolute
      right 5px
      top 14px
    

.election-dropdown
  right 10px
  width 260px
  text-align left
  position: absolute
  background-color mjn-yellow
  z-index: 1000
  color #000
  font-size 18px
  padding-top 10px
  &.active
    border-bottom 1px solid second-color-bright    
  span
    line-height 35px
    text-align right
    vertical-align middle
    &.glyphicon-chevron-down
      position absolute
      right 10px
      padding-top 5px

  &.kinds
    dropdown-height = 190px
    z-index: 999
    top (50 - dropdown-height)px
    background-color second-color
    display block
    height dropdown-height
    color #FFF
    text-align center
    
    &.moveDown
      transition 0.3s linear all;
      transform translateY(178px);
      // box-shadow -3px -5px 10px .5px #ccc
    &.moveOutDown
      transition 0.3s linear all;
      transform translateY(50);
      
    .element
      cursor pointer
      font-size 15px
      border-top 1px solid second-color-bright
      &:hover
        background-color second-color-bright
        color #FFF
      span
        padding-left 10px
.nav
  height 50px
  width 100%
  background-color main-color
  display block
  position fixed
  z-index 1000

.election-kinds
  height 30px
  background #DEDEDE
  position relative
  top 75px
  margin page-layout-margins
  
    
.menu
  height 100%
  width 200px
  display block
  position fixed
  top 50px
  left -200px
  z-index: 1000
  background second-color
  box-shadow 3px 5px 10px .5px #ccc
  color #FFF
  animation-duration 1s
  overflow-x auto
  &.moveLeft
    transform: translateX(200px);
  
  input
    margin 10px 0 10px 10px
    width 180px
    border none
    padding-left 10px
    height 30px

.choose-election-data
  text-align center
  top 40%
  position absolute

.spinner
  position fixed
  z-index: 1000
  background-color rgba(255,255,255, 0.8)
  display block
  top 0px
  height 100%

@media screen and (max-width: 996px)
  .choose-election-data
    width 100%